body {
  margin: 0;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Poppins", sans-serif, monospace;
}
@media (-webkit-device-pixel-ratio: 1.5) {
  * {
    zoom: 0.982;
  }
}
